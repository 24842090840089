import { makeStyles } from "@mui/styles";
import React, { PropsWithChildren, ReactNode, useState } from "react";
import FHButton from "../FHButton";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import Button from "@mui/material/Button";
import CloseIcon from "@mui/icons-material/Close";
import CardContent from "@mui/material/CardContent";
import Hidden from "@mui/material/Hidden";
import classnames from "classnames";
import Grid from "@mui/material/Grid";
import { isAWhitelistedBot, useDebounce } from "../../Utils";

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    zIndex: 1300,
    "& .MuiDrawer-paper": {
      minWidth: 400,
      maxWidth: "600px",
      [theme.breakpoints.down("sm")]: {
        maxWidth: "80%",
        minWidth: "80%",
      },
      [theme.breakpoints.down("xs")]: {
        maxWidth: "90%",
        minWidth: "90%",
      },
      borderRadius: `${theme.shape.borderRadius}px 0 0 ${theme.shape.borderRadius}px`,
    },
    "& .MuiCardHeader-root": {
      borderBottom: `1px solid ${theme.palette.divider}`,
      padding: theme.spacing(1.6, 1.5),
    },
    "& .MuiCardHeader-action": {
      margin: 0,
    },
  },
  fullWidthSideMenu: {
    "& .MuiDrawer-paper": {
      borderRadius: 0,
      [theme.breakpoints.down("md")]: {
        maxWidth: "100%",
        minWidth: "100%",
      },
    },
  },
  mobileDrawerContent: {
    transform: "translate(0, 0)",
    display: "flex",
    height: "100%",
    justifyContent: "flex-end",
    "& .MuiPaper-root": {
      height: "100%",
      width: "100%",
      backgroundColor: theme.palette.background.default,
    },
    "& .MuiDrawer-paper": {
      backgroundColor: "transparent",
      overflowY: "hidden",
    },
    "& .MuiCardActions-root": {
      marginTop: "auto",
    },
  },
  cardRoot: {
    [theme.breakpoints.up("md")]: {
      display: "flex",
      flexDirection: "column",
    },
  },
  closeButton: {
    color: theme.palette.secondary.main,
    borderColor: theme.palette.secondary.main,
    borderWidth: 2,
    padding: theme.spacing(0.3),
    minWidth: "auto",
    margin: theme.spacing(0, 0, 0, 1),
    "&:hover": {
      color: theme.palette.secondary.main,
      borderColor: theme.palette.secondary.main,
      borderWidth: 2,
    },
  },
  cardContentContainer: {
    position: "relative",
    height: "100%",
    padding: theme.spacing(0, 2),
  },
  cardContent: {
    padding: theme.spacing(2, 0, 0),
    width: "100%",
    height: "100%",
    [theme.breakpoints.up("md")]: {
      overflowY: "auto",
      overflowX: "hidden",
    },
  },
  buttonContainer: {
    display: "inline-block",
  },
  ssr: {
    display: "none",
  },
}));

type BookNowButtonProps = {
  label?: string;
  mobileWidgetLabel?: string;
  onSetMenuOpen?: (isOpen: boolean) => void;
  children?: React.ReactNode;
  fullWidthSideMenu?: boolean;
};

export const BookNowButton: React.FC<BookNowButtonProps> = ({
  label,
  mobileWidgetLabel = "Search",
  children,
  onSetMenuOpen,
  fullWidthSideMenu,
}: PropsWithChildren<BookNowButtonProps>) => {
  const classes = useStyles();

  const [menuOpen, setMenuOpen] = useState(false);

  const handleMenu = (isOpen: boolean) => {
    setMenuOpen(isOpen);
    onSetMenuOpen && onSetMenuOpen(isOpen);
  };

  const handleMenuClose = () => handleMenu(false);
  const handleToggle = () => handleMenu(!menuOpen);

  const debouncedMenuOpen = useDebounce(handleToggle, 200);

  return (
    <>
      <Grid className={classes.buttonContainer}>
        <FHButton fhSize="md" onClick={debouncedMenuOpen}>
          {label}
        </FHButton>
      </Grid>
      <Hidden mdUp>
        <SwipeableDrawer
          anchor="right"
          className={classnames(classes.mobileDrawer, {
            [classes.ssr]: isAWhitelistedBot(),
            [classes.fullWidthSideMenu]: fullWidthSideMenu,
          })}
          open={isAWhitelistedBot() ? true : menuOpen}
          onOpen={() => {}}
          onClose={handleMenuClose}
        >
          <div className={classes.mobileDrawerContent}>
            <Card className={classes.cardRoot}>
              <CardHeader
                title={mobileWidgetLabel}
                action={
                  <Button
                    className={classes.closeButton}
                    color="primary"
                    variant="outlined"
                    onClick={handleMenuClose}
                  >
                    <CloseIcon />
                  </Button>
                }
              />
              <div className={classes.cardContentContainer}>
                <CardContent className={classes.cardContent}>
                  {children}
                </CardContent>
              </div>
            </Card>
          </div>
        </SwipeableDrawer>
      </Hidden>
    </>
  );
};
